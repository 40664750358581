@import '~antd/dist/antd.less';

// Knockout 29 Junior Liteweight
@font-face {
  font-family: 'Knockout-HTF29-JuniorLiteweight';
  src: url('./res/fonts/Knockout-HTF29-JuniorLiteweight.woff2') format('woff2');
}

// Knockout 49 Liteweight
@font-face {
  font-family: 'Knockout-HTF49-Liteweight';
  src: url('./res/fonts/Knockout-HTF49-Liteweight.woff2') format('woff2');
}

body {
  border-radius: 2px;
  box-sizing: border-box;
  font-family: @font-body;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  font-family: @font-header-bold;
  font-size: 30px;
}

h2 {
  font-family: @font-header-bold;
  font-size: 30px;
}

h3 {
  margin-bottom: 0;
  font-family: @font-header;
  font-size: 24px;
}

h5 {
  font-size: 18px;
  font-weight: 600;
}

h6 {
  font-size: 0.83em;
  font-weight: 500;
}

.site-layout-content {
  background: #fff;
  min-height: calc(100vh - 150px);
  padding: 24px;
}

.App {
  text-align: left;
}

.track-layout {
  .header {
    background: @secondary-color;
    font-family: @font-header;
    height: 64px;
    display: flex;

    li {
      font-size: 18px;
    }

    .ant-menu {
      background: @secondary-color;

      span > a {
        color: white;
      }
    }

    .ant-menu-submenu-selected {
      background: @primary-color;
      color: white;
    }
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: @secondary-color;
  color: white;
  font-family: @font-header;
  font-size: 18px;

  span > a {
    color: white;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active {
    background: @primary-color;
    color: white;
  }
}

.logo {
  background-image: url(./res/img/Main_Logo_RGB_white.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin: 12px 24px 12px 0;
  width: 100px;
  flex-shrink: 0; /* Prevent logo from shrinking */
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.table-row-header,
.table-row-header:hover {
  background: @primary-color;
  font-family: @font-header;
  font-size: 18px;
}

.audits-by-issue-table {
  font-weight: bold;
}

.audits-by-issue-table .ant-table-thead > tr > th {
  background-color: @secondary-color;
  color: white;
  font-weight: bold;
  font-family: @font-header;
  font-size: 18px;
}

.esoy-pret-table {
  td.ant-table-column-sort {
    background: inherit;
  }
}

.esoy-pret-table .review_promote {
  background-color: #9cf79f;
}

.esoy-pret-table .review_retain {
  background-color: #f6f7ab;
}

.esoy-pret-table .review_exit {
  background-color: #f7a6a6;
}

.esoy-pret-table .review_transfer {
  background-color: #fcbf4e;
}

.esoy-pret-table .NA {
  background-color: rgba(255, 231, 199, 0.4);
}

.esoy-pret-table {
  tr.ant-table-row-selected > td {
    background-color: unset;
  }
}

.esoy-pret-table .review-error .ant-table-cell.show-pret-error {
  border: 2px dotted red;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}

[data-theme='dark'] .logo {
  background: rgba(255, 255, 255, 0.3);
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  cursor: pointer;
  padding: 5px 12px;
}

.editable-cell-value-wrap:hover {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
}

[data-theme='compact'] .seed-changes-panel-selected,
.seed-changes-panel-selected {
  background: #e6f7ff;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.input {
  display: flex;
  justify-content: space-between;
}

.text {
  resize: none;
  width: 48%;
}

.submit {
  display: block;
  margin: 10px auto;
  width: 100%;
}

.conversation {
  padding: 6px;
}

.comment {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px;
}

.editor {
  margin-top: 20px;
}

.diff-delete {
  background: #fadde0;
}

.diff-insert {
  background: #d6fedb;
}

.handsontable td.diff-modify-cell {
  background: #d6fedb;
  padding: 0;

  & > div {
    line-height: 21px;
    min-height: 21px;
    padding: 0 4px;
  }

  .strikethrough {
    text-decoration: line-through;
    background: #fadde0;
  }
}

.handsontable .diff-removed-cell {
  background: #d4aeb2;
}

.handsontable td.readonly-cell {
  background: #f5f5f5;
}

.jodit-wysiwyg {
  min-height: 400px;
  padding: 10px;
}

.jodit-editor .ant-modal-body {
  padding: 0;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  border-top: none !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

.site-collapse-panel-item {
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 26px 30px 26px 0;
    /* Adjust triangle size */
    border-color: transparent @primary-color transparent transparent;
    /* Triangle color */
  }
}

.site-collapse-custom-content > .ant-collapse-header {
  background-color: @primary-color;
  font-family: @font-header;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
}

.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-bottom: 0;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 0;
}

.chat-container {
  bottom: 30px;
  position: fixed;
  right: 30px;
  width: 400px;

  .chat-session {
    background-color: #fff;
    border: 2px solid var(--primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.chat-message {
  align-self: start;
  background-color: #f0f0f0;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  display: block;
  margin: 0 20px 0 0;
  padding: 0 10px;
  width: fit-content;
}

.tab-button {
  display: flex;
  justify-content: end;

  button {
    border-bottom: none;
    border-radius: 5px 5px 0 0;
  }
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset;
}

.ant-table-small {
  line-height: 1;
}

.ant-spin-nested-loading,
.ant-spin-spinning {
  width: 100%;
  min-height: 40px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 4px 15px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td.diff {
  padding: 0 4px;
}

.ant-table-tbody > tr > td.diff-modify-cell,
.ant-table-tbody > tr.ant-table-row-level-0:hover > td.diff-modify-cell,
.ant-table-tbody > tr.ant-table-row-level-0 > td.diff-modify-cell {
  background: #d6fedb;
  padding: 0;

  & > div {
    padding: 0 4px;
    line-height: 21px;
    min-height: 21px;
  }

  .strikethrough {
    text-decoration: line-through;
    background: #fadde0;
  }
}

.grey-background.ant-select:not(.ant-select-customize-input)
  > .ant-select-selector {
  background-color: #f9f9f9;
}

.ant-comment-content-author > a,
.ant-comment-content-author > span {
  color: @text-color;
}

@font-header: Knockout-HTF29-JuniorLiteweight, sans-serif;
@font-header-bold: Knockout-HTF49-Liteweight, sans-serif;
@font-body: 'Libre Franklin', sans-serif;

@primary-color: #ffb500; // primary color for all components
@secondary-color: #1034b4; // secondary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #ff6800; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: #000000; // heading text color
@text-color: #000000; // major text color
@text-color-secondary: #000000; // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base:
  0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

// export variables as css variables
:root {
  --primary-color: @primary-color;
  --secondary-color: @secondary-color;
  --link-color: @link-color;
  --success-color: @success-color;
  --warning-color: @warning-color;
  --error-color: @error-color;
  --heading-color: @heading-color;
  --text-color: @text-color;
  --text-color-secondary: @text-color-secondary;
  --disabled-color: @disabled-color;
  --box-shadow-base: @box-shadow-base;
}

